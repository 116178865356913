import React, {Fragment, useContext, useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import {AuthContext} from "../utils/auth-provider";

const title = "Login Using Facebook";

const LogIn = () => {
    const { isLoggedIn, handleFacebookLogin, validateAccessToken } = useContext( AuthContext );
    const navigate = useNavigate(); // Hook to navigate
    const {state} = useLocation();

    let path = "/";
    if(!!state && !!state.returnPath ){
        path = state.returnPath;
    }

    const socialList = [
        {
            imgUrl: "assets/images/match/fb_btn.png",
            imgAlt: "social",
            siteLink: "#",
            loginHandler: async() => {
                await handleFacebookLogin();
                navigate( path );
            },
        },
        /*    {
                    imgUrl: 'assets/images/match/social-2.png',
                    imgAlt: 'social',
                    siteLink: '#',
                },
                {
                    imgUrl: 'assets/images/match/social-3.png',
                    imgAlt: 'social',
                    siteLink: '#',
                },
            */
    ];

    useEffect( () => {
        const accessToken = localStorage.getItem( "accessToken" );
        if( !!accessToken && !isLoggedIn ) {
            ( async() => {
                let result = await validateAccessToken( accessToken );
                if( result === false ) {
                    //  현재는 facebook 로그인만 있음
                    await handleFacebookLogin();
                }
                navigate( path );
            } )();
        }
    } );


    return (
        <Fragment>
            <Header/>
            <br></br>
            <br></br>
            <div className="login-section padding-top padding-bottom">
                <div className="container">
                    <div className="account-wrapper">
                        <h3 className="title">{title}</h3>

                        <ul className="match-social-list d-flex flex-wrap align-items-center justify-content-center mt-4">
                            {socialList.map( ( val, i ) => (
                                <li key={i}>
                                    <a href={val.siteLink}>
                                        <img
                                            src={`${val.imgUrl}`}
                                            alt={`${val.imgAlt}`}
                                            onClick={val.loginHandler}
                                        />
                                    </a>
                                </li>
                            ) )}
                            <div className="container">
                                <br></br>
                                By Clicking on "Log in with Facebook" button,
                                you are agreeing to our
                                <a href='https://corp.playlinks.com/terms-of-service-eng' target='_blank' rel="noreferrer"><b>Terms of Services </b></a> <b>& </b>
                                <a href='https://corp.playlinks.com/privacy-policy-eng' target='_blank' rel="noreferrer"><b> Privacy Policy.</b></a>

                            </div>
                            <div className="container">
                                <br></br>
                                If you're using an iOS device, please use <b>Google Chrome</b> for optimal experience.
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
            <Footer/>
        </Fragment>
    );
};

export default LogIn;
