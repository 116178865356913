import React, { Fragment } from 'react';
import Footer from '../component/layout/footer';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/page-header';
import { Link } from "react-router-dom";

const FeaturedOne = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader title={'Video Poker'} curPage={'Featured Game 3'} />
            
                <div className="container">
                    <div className="section-wrapper">
                        <div className="row justify-content-center pb-15">
                            <div className="col-lg-8 col-12">
                                <article>
                                    <div className="post-item-2">
                                        <div className="post-inner">
                                            <div className="post-content">
                                                <ul className="lab-ul post-date">
                                                    <li><span><i className="icofont-ui-calendar"></i> June 30, 2023 10:00 am
                                                        </span></li>
                                                    <li><span><i className="icofont-user"></i>Vegas Craze Crew</span>
                                                    </li>
                                                </ul>
                                                <p>Head over the our video poker menu, and enjoy a variety of video poker games. <br></br>
                                                    Jacks or Better, Deuces Wild, Double Double, Triple Double and with multi play options!<br></br>
                                                    Our video poker was designed with the brick and mortar style games that we know our patrons love.
                                                    Hitting a Royal Flush is one of the sweetest feeling in the world!
                                                </p>
                                                <blockquote className="single-quote mb-30">
                                                    <div className="quotes">
                                                        Auto hold feature is a must if you want to practice your skill!<br></br>
                                                        You may want to hold other cards but this feature keeps you in line.<br></br>
                                                        But, you never know and when luck is on your side go with your guts!
                                                    </div>
                                                </blockquote>
                                                <div className="post-thumb mb-30"><img src="assets/images/blog/single/videopoker_gs.png" alt="blog-img" />
                                                </div>  

                                                <br></br>
                                                <Link to="/"><button type="link" className="default-button"><span>GO HOME<i className="icofont-circled-right"></i></span></button></Link>
                                                <br></br>

                                            </div>
                                        </div>
                                    </div>

                                </article>
                            </div>

                        </div>
                    </div>
                </div>
            
            <Footer />
        </Fragment>
    )
}

export default FeaturedOne