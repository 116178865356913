import React, { Fragment } from 'react';
import Footer from '../component/layout/footer';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/page-header';
import { Link } from "react-router-dom";

const FeaturedOne = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader title={'Triple Me Treasures'} curPage={'Featured Game 1'} />
            
                <div className="container">
                    <div className="section-wrapper">
                        <div className="row justify-content-center pb-15">
                            <div className="col-lg-8 col-12">
                                <article>
                                    <div className="post-item-2">
                                        <div className="post-inner">
                                            <div className="post-content">
                                                <h2>Triple your fun with triple me treasures!</h2>
                                                <ul className="lab-ul post-date">
                                                    <li><span><i className="icofont-ui-calendar"></i> June 30, 2023 10:00 pm
                                                        </span></li>
                                                    <li><span><i className="icofont-user"></i>Vegas Craze Crew</span>
                                                    </li>
                                                </ul>
                                                <p>
                                                Here's another fan favorite! <br></br>
                                                Triple Me Treasures Video Slots has been a long time favorite game. <br></br>
                                                Triple Pot Feature within the game is sure to please any avid Slot Player!<br></br>
                                                Spin away and capture the Grand Jackpot worth over 1000 times your bet!
                                                </p>
                                                <blockquote className="single-quote mb-30">
                                                    <div className="quotes">
                                                        Wheels, hold and spin, and massive multipliers!<br></br>
                                                        This is a special game with long list of modern bonus features.<br></br>
                                                        Definitely a fan favorite with long history of big payouts.
                                                    </div>
                                                </blockquote>
                                                <div className="post-thumb mb-30"><img src="assets/images/blog/single/triplemetreasures_gs.png" alt="triple-me-treasures" />
                                                </div>  

                                                <br></br>
                                                <Link to="/"><button type="link" className="default-button"><span>GO HOME<i className="icofont-circled-right"></i></span></button></Link>
                                                <br></br>

                                            </div>
                                        </div>
                                    </div>

                                </article>
                            </div>

                        </div>
                    </div>
                </div>
            
            <Footer />
        </Fragment>
    )
}

export default FeaturedOne