import {BrowserRouter, Routes, Route} from "react-router-dom";
import 'swiper/css';
import CookieConsent from "react-cookie-consent";


import ScrollToTop from "./component/layout/scrolltop";
import ErrorPage from "./page/404";
import ContactPage from "./page/contact";
import Home from "./page/home";
import ShopPage from "./page/shop";
import AuthProvider from "./utils/auth-provider";
import FeaturedOne from "./page/featured-1";
import FeaturedTwo from "./page/featured-2";
import FeaturedThree from "./page/featured-3";
import Login from "./page/login";
import AboutUs from "./page/about-us";

function App() {
    return (
        <BrowserRouter basename={process.env["PUBLIC_URL"]}>
            <ScrollToTop />
            <AuthProvider>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="*" element={<ErrorPage />} />
                {/*<Route path="shop" element={<ShopPage />} />*/}
                <Route path="contact" element={<ContactPage />} />
				<Route path="featured-1" element={<FeaturedOne />} />
				<Route path="featured-2" element={<FeaturedTwo />} />
				<Route path="featured-3" element={<FeaturedThree />} />
                <Route path="login" element={<Login />} />
                <Route path="about-us" element={<AboutUs />} />
            </Routes>
            </AuthProvider>
            <CookieConsent>This site uses cookies. Please refer to our <a href='https://corp.playlinks.com/privacy-policy-eng' target='_blank' rel="noreferrer"> privacy policy</a> for more information</CookieConsent>
        </BrowserRouter>
    );
}

export default App;
