import axios from "axios";


const NET = {
    getPlayerInfo: async function( platform, id, altId ) {
        try {
            const response = await axios.post( `/api/player/`, { platform: platform, id: id, altId: altId} );
            return response.data;
        } catch( error ) {
            console.error( error );
        }
    },

    getUserToken: async function( playerID, sku ) {
        try {
            const response = await axios.post( `/api/xsolla/getUserToken`, { playerID: playerID, sku: sku } );
            return response.data;
        } catch( error ) {
            console.error( error );
        }
    }

}

export default NET;