import React, { Fragment } from 'react';
import Footer from '../component/layout/footer';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/page-header';
import { Link } from "react-router-dom";

const FeaturedOne = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader title={'Golden Egg Drop'} curPage={'Featured Game 2'} />
            
                <div className="container">
                    <div className="section-wrapper">
                        <div className="row justify-content-center pb-15">
                            <div className="col-lg-8 col-12">
                                <article>
                                    <div className="post-item-2">
                                        <div className="post-inner">
                                            <div className="post-content">
                                                <h2>'Tis a Golden Time!</h2>
                                                <ul className="lab-ul post-date">
                                                    <li><span><i className="icofont-ui-calendar"></i> June 26, 2023 10:00 am
                                                        </span></li>
                                                    <li><span><i className="icofont-user"></i>Vegas Craze Crew</span>
                                                    </li>
                                                </ul>
                                                <p>
                                                The point of Golden Egg Drop game's feature is to collect as many eggs as possible<br></br>
                                                before the free spins run out.  You can also unlock multiple reels and increase your chance of winning
                                                the golden eggs!  Play now, your fun awaits!
                                                </p>
                                                <blockquote className="single-quote mb-30">
                                                    <div className="quotes">
                                                        This game is unique in that it contains our favorite MAP bonus<br></br>
                                                        Once you clear the map 5 times, path to special bonus is shortened!<br></br>
                                                        SPIN - COLLECT - WIN!
                                                    </div>
                                                </blockquote>
                                                <div className="post-thumb mb-30"><img src="assets/images/blog/single/goldeneggdrop_gs.png" alt="golden-egg-drop" />
                                                </div>  

                                                <br></br>
                                                <Link to="/"><button type="link" className="default-button"><span>GO HOME<i className="icofont-circled-right"></i></span></button></Link>
                                                <br></br>

                                            </div>
                                        </div>
                                    </div>

                                </article>
                            </div>

                        </div>
                    </div>
                </div>
            
            <Footer />
        </Fragment>
    )
}

export default FeaturedOne