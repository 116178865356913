import React from "react";
import {getImageUrl} from "../../utils/urls";
import {useContext} from "react";
import {AuthContext} from "../../utils/auth-provider";
import {useNavigate} from "react-router-dom";

// const titleh2 = "Play directly from our website!";
const titleh3 = "PLAY VEGAS CRAZE";
const titleh4 = "EAT - PLAY - SLOTS!";
const Banner = () => {
    const { isLoggedIn } = useContext( AuthContext );
    const navigate = useNavigate();

    function playCanvas() {
        window.location.href = process.env.REACT_APP_CANVAS_URL;
    }

    function login() {
        navigate( "/login", { state: { returnPath: "/" } } );
    }

    return (
        <section className="banner">
            <video
                autoPlay
                loop
                muted
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: -1,
                    opacity: 0.7
                }}
            >
                <source src="assets/images/banner/VC_V_app_preview_30s_en_la_231227_1024.mp4" type="video/mp4" />
            </video>


            <div className="container">
                <div className="row g-0">
                        <div className="banner__content" style={{"textAlign": "center", "alignItems": "center"}}>
                            {/*<br/>*/}
                            {/*<br/>*/}
                            <h3>{titleh3}</h3>
                            <h4>{titleh4}</h4>
                            <br/>
                            <div className="play-button-container">
                                <button className="play-button" onClick={playCanvas} >
                                    <label className="play-label">PLAY NOW</label>
                                </button>
                            </div>
                        </div>
                    </div>
            </div>
        </section>
    );
};

export default Banner;
