import {useState, useContext} from "react";
import {NavLink, Link} from 'react-router-dom';
import {AuthContext} from "../../utils/auth-provider";
import {getImageUrl} from "../../utils/urls";

const Header = () => {
    const [ menuToggle, setMenuToggle ] = useState( false );
    // const [ socialToggle, setSocialToggle ] = useState( false );
    const [ headerFiexd, setHeaderFiexd ] = useState( false );
    const { isLoggedIn, handleFacebookLogout, playerInfo } = useContext( AuthContext );

    const socialList = [
        {
            iconName: 'icofont-facebook-messenger',
            siteLink: 'https://www.messenger.com/t/108965233918747/',
        },
        {
            iconName: 'icofont-facebook',
            siteLink: 'https://www.facebook.com/gaming/VegasCraze',
        }
    ]

    window.addEventListener( "scroll", () => {
        if( window.scrollY > 200 ) {
            setHeaderFiexd( true );
        } else {
            setHeaderFiexd( false );
        }
    } );


    return (
        <header className={`header-section style2 ${headerFiexd ? "header-fixed fadeInUp" : ""}`}>
        <div className="container">
                <div className="header-holder d-flex flex-wrap justify-content-between align-items-center">
                    <div className="brand-logo d-none d-lg-inline-block py-2">
                        <div className="logo">
                            <Link to="/">
                                <img src={getImageUrl( "assets/images/logo/logo.png" )} alt="logo"/>
                            </Link>
                        </div>
                    </div>
                    <div className="header-menu-part">
                        <div className="header-top-area">
                            <ul className="left">
                            </ul>
                            <ul className="social-icons d-flex align-items-center">
                                {socialList.map( ( val, i ) => (
                                    <li key={i}>
                                        <a href={val.siteLink} target="_blank" rel="noreferrer"><i
                                            className={val.iconName}></i></a>
                                    </li>
                                ) )}
                                <li>
                                    <Link to='/contact'><i className='icofont-email'></i></Link>
                                </li>
                            </ul>
                        </div>
                        <div className="header-bottom">
                            <div className="header-wrapper justify-content-lg-end">
                                <div className="mobile-logo d-lg-none">
                                    <Link to="/"><img src={getImageUrl( "assets/images/logo/logo.png" )}
                                                      alt="logo"/></Link>
                                </div>
                                <div className="menu-area">
                                    <ul className={`menu ${menuToggle ? "active" : ""}`}>
                                        <li><NavLink to="/">Home</NavLink></li>
                                        <li className="menu-item-has-children">
                                            <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true"
                                               aria-expanded="false">Feature</a>
                                            <ul className="submenu dropdown-menu">
                                                <li><NavLink to="/featured-1">Triple Me Treasures</NavLink></li>
                                                <li><NavLink to="/featured-2">Golden Egg Drop</NavLink></li>
                                                <li><NavLink to="/featured-3">Video Poker</NavLink></li>
                                            </ul>
                                        </li>
                                        {/*<li><NavLink to="/shop">Shop</NavLink></li>*/}
                                        <li><NavLink to="/contact">Contact</NavLink></li>

                                        {isLoggedIn ? (
                                            <li className="menu-item-has-children">
                                                <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true"
                                                   aria-expanded="false">{playerInfo.name}</a>
                                                <ul className="submenu dropdown-menu">
                                                    <li><a href="#" onClick={handleFacebookLogout}>Logout</a></li>
                                                </ul>
                                            </li>
                                        ) : (
                                            <li className="menu-item-has-children">
                                                <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true"
                                                   aria-expanded="false">Account</a>
                                                <ul className="submenu dropdown-menu">
                                                    <li><NavLink to="/login">Facebook Log In</NavLink></li>
                                                </ul>
                                            </li>
                                        )}
                                    </ul>
                                    <div className={`header-bar d-lg-none ${menuToggle ? "active" : ""}`}
                                         onClick={() => setMenuToggle( !menuToggle )}>
                                    </div>
                                    <div className={`header-bar d-lg-none ${menuToggle ? "active" : ""}`}
                                         onClick={() => setMenuToggle( !menuToggle )}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;

