import React, { Fragment, useContext } from 'react';
import Footer from '../component/layout/footer';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/page-header';
import { AuthContext } from "../utils/auth-provider";
import { getImageUrl } from "../utils/urls";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

const conTitle = "Let us know your needs.";

const ContactPage = () => {
    const [formStatus, setFormStatus] = React.useState('Send Message');
    const [isEmailSent, setIsEmailSent] = React.useState(false);
    const { isLoggedIn, playerInfo } = useContext(AuthContext);
    const navigate = useNavigate();

    const onSubmit = async (e) => {
        e.preventDefault();
        setFormStatus('Sending...');

        let info = playerInfo;
        try {
            if (!isLoggedIn) {
                navigate('/login', { state: { returnPath: '/contact' } });
                return;
            }

            const { email, subject, message } = e.target.elements;

            let formData = {
                facebookASID: info.id,
                playerID: info.playerID,
                name: info.name,
                email: email.value,
                title: subject.value,
                message: message.value
            };

            await axios.post('/api/send-mail/', formData);

            // Reset the form and update the state to show the confirmation screen
            email.value = '';
            message.value = '';
            subject.value = '';
            setIsEmailSent(true);
            setFormStatus('Send Message');
        } catch (error) {
            console.log(error);
            setFormStatus('Send Message');
        }
    };

    return (
        <Fragment>
            <Header />
            <PageHeader title={'Contact Us'} curPage={'Contact'} />
            {!isEmailSent ? (
                <div className="contact-section">
                    <div className="contact-top padding-top padding-bottom bg-attachment"
                         style={{ backgroundImage: `url(${getImageUrl("assets/images/video/bg.jpg")})` }}>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-10">
                                    <div className="contact-form-wrapper text-center">
                                        <div className="section-header">
                                            <h2>{conTitle}</h2>
                                        </div>
                                        <form className="contact-form" onSubmit={onSubmit}>
                                            <div className="form-group">
                                                <input type="text" placeholder="Your Email" id="email" name="email"
                                                       required />
                                            </div>
                                            <div className="form-group">
                                                <input type="text" placeholder="Subject" id="subject" name="subject"
                                                       required />
                                            </div>
                                            <div className="form-group w-100">
                        <textarea name="message" rows="8" id="message" placeholder="Your Message"
                                  required></textarea>
                                            </div>
                                            <div className="form-group w-100 text-center">
                                                <button className="default-button" type="submit">
                                                    <span>{formStatus}</span>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="confirmation-section">
                    <div className="contact-top padding-top padding-bottom bg-attachment"
                         style={{ backgroundImage: `url(${getImageUrl("assets/images/video/bg.jpg")})` }}>
                        <div className="container">
                            <div className="confirmation-wrapper">
                                <h2>Your email has been sent!</h2>
                                <p>Thank you for contacting us. We will get back to you as soon as possible.</p>
                                <button className="default-button" onClick={() => setIsEmailSent(false)}>
                                    <span>Go Back</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Footer />
        </Fragment>
    )
}

export default ContactPage;
