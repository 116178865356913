import {Link} from "react-router-dom";
import RatingTwo from "../sidebar/rating-2";
import {getImageUrl} from "../../utils/urls";

const desc = "Play VEGAS CRAZE CASINO!! Our motto is to EAT - PLAY - SLOTS!!   From Classic Reels to exciting Video Slots, we've got it all!  Join now, YOUR FUN awaits!!";
const blogTitle = "Featured Games";
const companyInfo = "Company Info";
// const newsDesc = "Sign up to receive our monthly newsletter!";

const socialList = [
    {
        imgUrl: 'assets/images/match/social-1.png',
        imgAlt: 'social',
        siteLink: 'https://www.facebook.com/VegasCraze',
    },
    {
        imgUrl: 'assets/images/match/social-2.png',
        imgAlt: 'social',
        siteLink: 'https://www.messenger.com/t/108965233918747/',
    },
]

const blogList = [
    {
        imgUrl: 'assets/images/footer/triplemetreasures.png',
        imgAlt: 'footer-widget-img',
        title: 'Triple Me Treasures',
        name: 'Main Feature',
        price: ' Triple Pot',
        siteLink: '/featured-1',

    },
    {
        imgUrl: 'assets/images/footer/goldeneggdrop.png',
        imgAlt: 'footer-widget-img',
        title: 'Golden Egg Drop',
        name: 'Main Feature',
        price: ' Upgraded LINK Bonus',
        siteLink: '/featured-2',
    },
    {
        imgUrl: 'assets/images/footer/videopoker.png',
        imgAlt: 'footer-widget-img',
        title: 'Video Poker',
        name: 'Main Feature',
        price: ' Auto Hold',
        siteLink: '/featured-3',
    },
]

const Footer = () => {
    return (
        <footer className="footer-section"
                style={{ backgroundImage: `url(${getImageUrl( "assets/images/footer/bg.jpg" )})` }}>
            <div className="footer-middle padding-top padding-bottom">
                <div className="container">
                    <div className="row padding-lg-top">
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="footer-middle-item-wrapper">
                                <div className="footer-middle-item mb-lg-0">
                                    <div className="fm-item-title mb-4">
                                        <img src={getImageUrl( "assets/images/logo/logo.png" )} alt="logo"/>
                                    </div>
                                    <div className="fm-item-content">
                                        <p className="mb-4">{desc}</p>
                                        <ul className="match-social-list d-flex flex-wrap align-items-center">
                                            {socialList.map( ( val, i ) => (
                                                <li key={i}><a href={val.siteLink}><img
                                                    src={`${getImageUrl( val.imgUrl )}`} alt={`${val.imgAlt}`}/></a>
                                                </li>
                                            ) )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="footer-middle-item-wrapper">
                                <div className="footer-middle-item mb-lg-0">
                                    <div className="fm-item-title">
                                        <h4>{blogTitle}</h4>
                                    </div>
                                    <div className="fm-item-content">
                                        {blogList.map( ( val, i ) => (
                                            <div className="fm-item-widget lab-item" key={i}>
                                                <div className="lab-inner">
                                                    <div className="lab-thumb">
                                                        <Link to={val.siteLink}> <img
                                                            src={`${getImageUrl( val.imgUrl )}`} alt={`${val.imgAlt}`}
                                                            className="rounded-3"/></Link>
                                                    </div>
                                                    <div className="lab-content">
                                                        <Link to={val.siteLink}><h6>{val.title}</h6></Link>
                                                        <p>{val.name}: <b>{val.price}</b></p>
                                                        <RatingTwo/>
                                                    </div>
                                                </div>
                                            </div>
                                        ) )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="footer-middle-item-wrapper">
                                <div className="footer-middle-item-3 mb-lg-0">
                                    <div className="fm-item-title">
                                        <h4>{companyInfo}</h4>
                                    </div>
                                    <div className="fm-item-content">
                                        <div className="lab-content">
                                            <Link to='/About-Us'><b>About Us</b></Link>
                                            <br></br>
                                            <a href='https://corp.playlinks.com/privacy-policy-eng' target='_blank'
                                               rel="noreferrer"><b>Privacy Policy</b></a>
                                            <br></br>
                                            <a href='https://corp.playlinks.com/terms-of-service-eng' target='_blank'
                                               rel="noreferrer"><b>Terms of Service</b></a>
                                            <br></br>
                                            <a href='https://corp.playlinks.com/gdpr' target='_blank'
                                               rel="noreferrer"><b>GDPR</b></a>
                                            <br></br>
                                            <a href='https://corp.playlinks.com/self-exclusion-policy' target='_blank'
                                               rel="noreferrer"><b>Self Exclusion Policy</b></a>
                                            <br></br>
                                            <a href='https://corp.playlinks.com/responsible-gaming-policy' target='_blank'
                                               rel="noreferrer"><b>Responsible Gaming Policy</b></a>
                                            <br></br>
                                            <p>Email: support@vegascraze.com</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="footer-bottom-content text-center">
                                <p>&copy;2023 <a href='https://corp.playlinks.com/' target='_blank' rel="noreferrer">PLAYLINKS
                                    Corp.</a> - All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;