import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import Banner from "../component/section/banner";
import Faq from "../component/section/faq";

const Home = () => {
    return (
        <>
            <Header />
            <Banner />
            <Faq />
            <Footer />
        </>
    );
};

export default Home;
