import {Link} from 'react-router-dom';

const PageHeader = ({title, curPage}) => {
    return (
        <section className="pageheader-section" >
            <div className="container">
                <div className="section-wrapper text-center text-uppercase">
                    <h2 className="pageheader-title">{title}</h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-center mb-0">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">{curPage}</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </section>
    );
}

export default PageHeader;