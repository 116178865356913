import {createContext, useState} from "react";
import Net from "../net";

export const AuthContext = createContext();

function AuthProvider( props ) {
    const [isLoggedIn, setIsLoggedIn] = useState( false );
    const [playerInfo, setPlayerInfo] = useState( null );
    const [isLoading, setIsLoading] = useState( false ); // New state variable


    const validateAccessToken = async( accessToken ) => {
        try {
            setIsLoading( true ); // Set isLoading to true when validating the access token

            const response = await fetch(
                `https://graph.facebook.com/v15.0/me?fields=instant_game_player_id,id,name&access_token=${accessToken}`
            );

            if( !response.ok ) {
                throw new Error( "Failed to validate access token" );
            }

            const data = await response.json();

            // Fetch player info if validation successful
            const platform = "canvas";
            const result = await Net.getPlayerInfo( platform, data.instant_game_player_id, data.id );
            if( !!result && !!result[ "userInfo" ] ) {
                Object.assign( data, result[ "userInfo" ] );

                const classToName = {
                    0: "TOPAZ",
                    1: "EMERALD",
                    2: "RUBY",
                    3: "DIAMOND",
                    4: "ELITE_DIAMOND",
                    5: "ROYAL_DIAMOND",
                    6: "ZENITH"
                };
                data.className = classToName[ result[ "userInfo" ][ "class" ] ];

                // window.gtag( 'config', process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID, {
                //     'user_id': `${data.playerID}`,
                //     'user_properties': {
                //         'player_id': `${data.playerID}`,
                //         'player_class': data.className
                //     }
                // } );

                window.gtag( 'set', 'user_id', data.playerID );
                window.gtag( 'set', 'user_properties', { 'player_id': data.playerID, 'player_class': data.className } );
            }
            setPlayerInfo( data );
            setIsLoggedIn( true );
            setIsLoading( false ); // Set isLoading to false after successful login

            return true;
        } catch( error ) {
            setIsLoading( false ); // Set isLoading to false if an error occurs during validation
            localStorage.removeItem( "accessToken" );
            console.error( error );
            return false;
        }
    };

    const handleFacebookLogin = async() => {
        try {
            setIsLoading( true ); // Set isLoading to true when starting the login process

            const response = await new Promise( ( resolve ) =>
                window.FB.login( resolve )
            );
            if( !response || response.error ) {
                throw new Error( `Failed to login FB : ${response.error}` );
            }
            localStorage.setItem( "accessToken", response.authResponse.accessToken );

            console.log( "Welcome! Fetching your information...." );

            await validateAccessToken( response.authResponse.accessToken );
        } catch( error ) {
            setIsLoading( false ); // Set isLoading to false if an error occurs during login
            console.error( error );
        }
    };

    const handleFacebookLogout = async() => {
        if( window.FB && window.FB.getAuthResponse() ) {
            await new Promise( ( resolve ) =>
                window.FB.logout( resolve )
            );
        }
        setIsLoggedIn( false );
        setPlayerInfo( null );
        localStorage.removeItem( "accessToken" );
    };

    return (
        <AuthContext.Provider
            value={{
                isLoggedIn,
                setIsLoggedIn,
                handleFacebookLogin,
                handleFacebookLogout,
                playerInfo,
                validateAccessToken
            }}
        >
            {isLoading && (
                // Show loading overlay when isLoading is true
                <div className="loading-overlay">
                    <div className="loading-content">
                        <div className="loading-message">
                            Logging In...<br/>
                        </div>
                    </div>
                </div>
            )}
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;
