const RatingTwo = () => {
    return (
        <div className="rating">
            <i className="icofont-ui-rating"></i>
            <i className="icofont-ui-rating"></i>
            <i className="icofont-ui-rating"></i>
            <i className="icofont-ui-rating"></i>
            <i className="icofont-ui-rating"></i>
        </div>
    );
}

export default RatingTwo;