import React, { Fragment } from 'react';
import Footer from '../component/layout/footer';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/page-header';
import { Link } from "react-router-dom";

const FeaturedOne = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader title={'ABOUT US'} curPage={'About Us'} />

            <div className="container">
                <div className="section-wrapper">
                    <div className="row justify-content-center pb-15">
                        <div className="col-lg-8 col-12">
                            <article>
                                <div className="post-item-2">
                                    <div className="post-inner">
                                        <div className="post-content">
                                            <h2>PLAYLINKS - RAON STUDIO</h2>

                                            <p>
                                                Since 2015, PLAYLINKS has always been a pioneer in the casual games industry.  Our goal is to develop games that are played worldwide.
                                                <p></p>
                                                Since our foundation in 2012, we have developed and launched over 8 games that have been played by thousands. We are seeking to keep up with the current trends and technologies and to provide the best gaming experiences for all consumers.
                                                <p></p>
                                                PLAYLINKS will try our best to provide a unique and unforgettable experience for everyone in all age groups. We will continue to strive to develop and provide games to increase the fulfillment and joy for everyone.
                                            </p>
                                            <p><a href='https://corp.playlinks.com/english' target='_blank' rel="noreferrer">Click here </a> to visit our Corporate Site</p>
                                            <blockquote className="single-quote mb-30">
                                                <div className="quotes">
                                                    <center><h3>EAT - PLAY - SLOTS!!</h3></center>
                                                </div>
                                            </blockquote>
                                            <container>
                                                <br></br>
                                                <Link to="/"><button type="link" className="default-button"><span>GO HOME<i className="icofont-circled-right"></i></span></button></Link>
                                                <br></br>
                                            </container>
                                        </div>
                                    </div>
                                </div>

                            </article>
                        </div>

                    </div>
                </div>
            </div>

            <Footer />
        </Fragment>
    )
}

export default FeaturedOne